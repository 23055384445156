import { useTranslation } from "react-i18next";
import styles from "./Yoga.module.css";
import SectionComponent from "./SectionComponent";
import ContactForm from "../../components/ContactForm/ContactForm";
import Footer from "../../components/Footer/Footer";

import yogaDandasana from "../../assets/yoga/yogaDandasana.webp";
import swamiji from "../../assets/yoga/swamiji.webp";
import kukkutasana from "../../assets/yoga/kukkutasana.webp";

const Yoga = () => {
  const { t } = useTranslation("yoga");

  const content = t("content", { returnObjects: true });
  const mantras = t("mantras", { returnObjects: true });

  const images = [yogaDandasana, swamiji, kukkutasana];

  return (
    <>
      <div className={styles.yogaContainer}>
        {content.map((text, index) => {
          const isLeftAligned = index % 2 === 0;
          return (
            <div key={index}>
              <SectionComponent
                isLeftAligned={isLeftAligned}
                image={images[index]}
                text={text}
              />
              {index < mantras.length && (
                <div className={styles.mantraBorder}>{mantras[index]}</div>
              )}
            </div>
          );
        })}
        <ContactForm page="yoga" />
      </div>
      <Footer />
    </>
  );
};

export default Yoga;
