import { useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import emailjs from "@emailjs/browser";
import { motion, useInView } from "framer-motion";
import { useMediaQuery } from "react-responsive";
import styles from "./ContactForm.module.css";
import useContactFormValidation from "../../hooks/useContactFormValidation";

const ContactForm = ({ page }) => {
  const { t } = useTranslation("contactForm");

  // State for form data and submission feedback
  const [formData, setFormData] = useState({ name: "", email: "", phone: "", message: "" });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");

  // Validation hook
  const { errors, isFormValid } = useContactFormValidation(formData);

  // Responsive handling and animations
  const ref = useRef(null);
  const inView = useInView(ref, { once: false });
  const isDesktop = useMediaQuery({ minWidth: 768 });

  // Dynamic content
  const infoItems = [
    t(`pageContent.${page}`),
    t("additionalInfo", { location: isDesktop ? t("location.right") : t("location.below") }),
  ];

  const formFields = [
    { id: "name", label: t("formLabels.name"), type: "text" },
    { id: "email", label: t("formLabels.email"), type: "email" },
    { id: "phone", label: t("formLabels.phone"), type: "tel" },
    { id: "message", label: t("formLabels.message"), type: "textarea" },
  ];

  const handleChange = (e) => setFormData({ ...formData, [e.target.name]: e.target.value });

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    const serviceId = process.env.REACT_APP_EMAILJS_SERVICE_ID;
    const templateId = process.env.REACT_APP_EMAILJS_TEMPLATE_ID;
    const userId = process.env.REACT_APP_EMAILJS_USER_ID;

    emailjs.send(serviceId, templateId, formData, userId)
      .then(() => {
        setResponseMessage(t("responseMessages.success"));
        setIsSubmitting(false);
        setTimeout(() => {
          setResponseMessage("");
          setFormData({ name: "", email: "", phone: "", message: "" });
        }, 5000);
      })
      .catch(() => {
        setResponseMessage(t("responseMessages.error"));
        setIsSubmitting(false);
        setTimeout(() => setResponseMessage(""), 5000);
      });
  };

  return (
    <div ref={ref} className={styles.container}>
      {/* Contact Form Information */}
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: inView ? 1 : 0 }}
        transition={{ duration: 1 }}
        className={styles.infoContainer}
      >
        <h2 className={styles.infoTitle}>{t("title")}</h2>
        {infoItems.map((item, idx) => (
          <p key={idx} className={styles.infoText}>{item}</p>
        ))}
      </motion.div>

      {/* Contact Form */}
      <motion.form
        onSubmit={handleSubmit}
        initial={{ opacity: 0 }}
        animate={{ opacity: inView ? 1 : 0 }}
        transition={{ duration: 1 }}
        className={styles.contactForm}
      >
        {formFields.map(({ id, label, type }) => (
          <div key={id} className={styles.formGroup}>
            <div className={styles.labelWrapper}>
              <label htmlFor={id} className={styles.label}>
                {label}
              </label>
              {errors[id] && <span className={styles.error}>{errors[id]}</span>}
            </div>
            {type === "textarea" ? (
              <textarea
                id={id}
                name={id}
                value={formData[id]}
                onChange={handleChange}
                className={styles.inputField}
                placeholder={t(`placeholders.${id}`)}
                style={{ height: "5rem" }}
              />
            ) : (
              <input
                type={type}
                id={id}
                name={id}
                value={formData[id]}
                onChange={handleChange}
                className={styles.inputField}
                placeholder={t(`placeholders.${id}`)}
              />
            )}
          </div>
        ))}
        {responseMessage && (
          <p className={styles.responseMessage}>{responseMessage}</p>
        )}
        <button
          type="submit"
          disabled={isSubmitting || !isFormValid}
          className={styles.submitButton}
        >
          {isSubmitting ? t("buttonSubmitting") : t("button")}
        </button>
      </motion.form>
    </div>
  );
};

export default ContactForm;
