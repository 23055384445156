import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";
import styles from "./Discography.module.css";

import Juliana from "../../assets/discography/Juliana.webp";
import WorthIt from "../../assets/discography/WorthIt.webp";
import Popstar from "../../assets/discography/Popstar.webp";
import HeavenOnEarth from "../../assets/discography/HeavenOnEarth.webp";
import LoseURMind from "../../assets/discography/LoseURMind.webp";
import SongsAtWork from "../../assets/discography/SongsAtWork.webp";
import Galaxy from "../../assets/discography/Galaxy.webp";

const Discography = () => {
  const { t } = useTranslation("discography");

  const albums = [
    { title: "Juliana", imageUrl: Juliana },
    { title: "Worth It (Was It All)", imageUrl: WorthIt },
    { title: "Popstar", imageUrl: Popstar },
    { title: "If Heaven Was A Place On Earth", imageUrl: HeavenOnEarth },
    { title: "Lose UR Mind", imageUrl: LoseURMind },
    { title: "songs i made at work", imageUrl: SongsAtWork },
    { title: "Galaxy", imageUrl: Galaxy }
  ].reverse();

  return (
    <div className={styles.discographyContainer}>
      <h2 className={styles.discTitle}>{t("title")}</h2>
      <div className={styles.albumGrid}>
        {albums.map((album, index) => (
          <motion.div
            className={styles.album}
            key={index}
            whileInView={{ opacity: 1 }}
            initial={{ opacity: 0 }}
            transition={{ duration: 0.5 }}
          >
            <img src={album.imageUrl} alt={album.title} className={styles.albumImage} />
          </motion.div>
        ))}
      </div>
    </div>
  );
};

export default Discography;
