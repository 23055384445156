import { useTranslation } from "react-i18next";
import headshotBW from "../../assets/headshotBW.webp";
import ContactForm from "../../components/ContactForm/ContactForm";
import Footer from "../../components/Footer/Footer";
import styles from "./About.module.css";

const About = () => {
  const { t } = useTranslation("about");

  return (
    <>
      <div className={styles.aboutContainer}>
        <h1 className={styles.aboutTitle}>{t("title")}</h1>
        <img
          src={headshotBW}
          alt="Jordan Olguin"
          className={styles.aboutImage}
        />
        <p className={styles.aboutText}>{t("bioParagraph")}</p>
        <ContactForm page="about" />
      </div>
      <Footer />
    </>
  );
};

export default About;
