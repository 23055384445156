import { useTranslation } from "react-i18next";
import WorkScroller from "../../components/WorkScroller/WorkScroller";
import ContactForm from "../../components/ContactForm/ContactForm";
import Footer from "../../components/Footer/Footer";
import PageBanner from "../../components/PageBanner/PageBanner";
import styles from "./Development.module.css";

const Development = () => {
  const { t } = useTranslation("development");

  return (
    <>
      <PageBanner
        title={t("title")}
        subtitle={t("subtitle")}
      />
      <div className={styles.developmentPage}>
        <section className={styles.paragraphSection}>
          <p className={styles.developmentText}>{t("paragraph")}</p>
        </section>
        <section className={styles.titleSection}>
          <h1 className={styles.developmentTitle}>{t("projects")}</h1>
        </section>
        <section className={styles.workScrollerSection}>
          <WorkScroller />
        </section>
        <ContactForm page="development" />
      </div>
      <Footer />
    </>
  );
};

export default Development;
