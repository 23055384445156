import { useState } from "react";
import { useTranslation } from "react-i18next";
import bass from "../../assets/bass.webp";
import Discography from "../../components/Discography/Discography";
import ContactForm from "../../components/ContactForm/ContactForm";
import Footer from "../../components/Footer/Footer";
import styles from "./Music.module.css";

const Music = () => {
  const { t } = useTranslation("music");
  const [hoveredLinkIndex, setHoveredLinkIndex] = useState(null);

  const streamingLinks = t("streamingLinks", { returnObjects: true });

  const handleMouseEnter = (index) => {
    setHoveredLinkIndex(index);
  };

  const handleMouseLeave = () => {
    setHoveredLinkIndex(null);
  };

  return (
    <>
      <div className={styles.musicContainer}>
        <img src={bass} alt="Jordan Olguin playing bass" className={styles.bannerImage} />
        <div className={styles.bioContainer}>
          <p className={styles.bio}>{t("bio")}</p>
        </div>
        <h2 className={styles.title}>{t("listenNow")}</h2>
        <div className={styles.linksContainer}>
          {streamingLinks.map((link, index) => (
            <a
              key={link.name}
              href={link.url}
              target="_blank"
              rel="noopener noreferrer"
              className={`${styles.link} ${
                hoveredLinkIndex !== null && hoveredLinkIndex !== index
                  ? styles.otherLinks
                  : ""
              }`}
              onMouseEnter={() => handleMouseEnter(index)}
              onMouseLeave={handleMouseLeave}
            >
              {link.name}
            </a>
          ))}
        </div>
        <Discography />
        <ContactForm page="music" />
      </div>
      <Footer />
    </>
  );
};

export default Music;
