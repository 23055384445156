import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import aboutTranslations from "./translations/about.json";
import actingTranslations from "./translations/acting.json";
import developmentTranslations from "./translations/development.json";
import musicTranslations from "./translations/music.json";
import yogaTranslations from "./translations/yoga.json";
import footerTranslations from "./translations/footer.json";
import contactFormTranslations from "./translations/contactForm.json";
import discographyTranslations from "./translations/discography.json";
import mainMenuTranslations from "./translations/mainMenu.json";
import titlesTranslations from "./translations/titles.json";

const resources = {
  en: {
    about: aboutTranslations.en,
    acting: actingTranslations.en,
    development: developmentTranslations.en,
    music: musicTranslations.en,
    yoga: yogaTranslations.en,
    footer: footerTranslations.en,
    contactForm: contactFormTranslations.en,
    discography: discographyTranslations.en,
    mainMenu: mainMenuTranslations.en,
    titles: titlesTranslations.en,
  },
  es: {
    about: aboutTranslations.es,
    acting: actingTranslations.es,
    development: developmentTranslations.es,
    music: musicTranslations.es,
    yoga: yogaTranslations.es,
    footer: footerTranslations.es,
    contactForm: contactFormTranslations.es,
    discography: discographyTranslations.es,
    mainMenu: mainMenuTranslations.es,
    titles: titlesTranslations.es,
  },
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: "en", // Default language
    fallbackLng: "en", // Fallback language
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
