import { useTranslation } from "react-i18next";
import ContactForm from "../../components/ContactForm/ContactForm";
import Footer from "../../components/Footer/Footer";
import wedding from "../../assets/wedding.webp";
import styles from "./Acting.module.css";

const Acting = () => {
  const { t } = useTranslation("acting");

  const theatreCredits = t("theatre", { returnObjects: true });
  const filmCredits = t("film", { returnObjects: true });

  return (
    <>
      <div className={styles.actingContainer}>
        <img src={wedding} alt="Jordan Olguin in a suit" className={styles.bannerImage} />
        <div className={styles.bioContainer}>
          <p className={styles.bio}>{t("bio")}</p>
        </div>

        <h2 className={styles.title}>{t("theatreCredits")}</h2>
        <ul className={styles.creditsList}>
          {theatreCredits.map((credit, index) => (
            <li key={index} className={styles.creditItem}>
              <span className={styles.role}>{credit.role}</span> {t("in")}{" "}
              <span className={styles.production}>{credit.production}</span>,{" "}
              {credit.company}
            </li>
          ))}
        </ul>

        <h2 className={styles.title}>{t("filmCredits")}</h2>
        <ul className={styles.creditsList}>
          {filmCredits.map((credit, index) => (
            <li key={index} className={styles.creditItem}>
              <span className={styles.title}>{credit.title}</span> {t("as")}{" "}
              <span className={styles.role}>{credit.role}</span>, {t("directedBy")}{" "}
              <span className={styles.director}>{credit.director}</span>
            </li>
          ))}
        </ul>

        <a
          href="https://www.imdb.com/name/nm9819820/?ref_=nv_sr_srsg_0_tt_0_nm_8_in_0_q_jordan%2520olguin"
          target="_blank"
          rel="noopener noreferrer"
          className={styles.imdbLink}
        >
          {t("imdbLink")}
        </a>
        <ContactForm page="acting" />
      </div>
      <Footer />
    </>
  );
};

export default Acting;
