import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

function useTitle() {
  const location = useLocation();
  const { t } = useTranslation("titles");

  useEffect(() => {
    const baseTitle = t("baseTitle");
    let title;

    switch (location.pathname) {
      case "/":
        title = t("home");
        break;
      case "/about":
        title = t("about", { base: baseTitle });
        break;
      case "/music":
        title = t("music", { base: baseTitle });
        break;
      case "/relax":
        title = t("relax", { base: baseTitle });
        break;
      case "/development":
        title = t("development", { base: baseTitle });
        break;
      case "/yoga":
        title = t("yoga", { base: baseTitle });
        break;
      case "/acting":
        title = t("acting", { base: baseTitle });
        break;
      default:
        title = baseTitle;
        break;
    }

    document.title = title;
  }, [location, t]);
}

export default useTitle;
