import React from "react";
import { useLanguage } from "../../contexts/LanguageContext";
import styles from "./LanguageToggle.module.css";

const languages = [
  { code: "en", label: "En" },
  { code: "es", label: "Es" },
];

const LanguageToggle = () => {
  const { language, toggleLanguage } = useLanguage();

  const handleLanguageChange = (code) => {
    if (language !== code) toggleLanguage(code);
  };

  return (
    <div className={styles.toggleContainer}>
      {languages.map((lang, index) => (
        <React.Fragment key={lang.code}>
          <span
            className={`${styles.toggleOption} ${
              language === lang.code ? styles.active : styles.inactive
            }`}
            onClick={() => handleLanguageChange(lang.code)}
          >
            {lang.label}
          </span>
          {index < languages.length - 1 && (
            <span className={styles.separator}>|</span>
          )}
        </React.Fragment>
      ))}
    </div>
  );
};

export default LanguageToggle;
