import { useState } from "react";
import { motion } from "framer-motion";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import styles from "./MainMenu.module.css";

const MainMenu = () => {
  const { t } = useTranslation("mainMenu");

  const [hoveredLinkIndex, setHoveredLinkIndex] = useState(null);
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const navLinks = [
    { path: "/", label: t("links.home") },
    { path: "/about", label: t("links.about") },
    { path: "/music", label: t("links.music") },
    { path: "/yoga", label: t("links.yoga") },
    { path: "/acting", label: t("links.acting") },
    { path: "/development", label: t("links.development") },
    { path: "/relax", label: t("links.relax") }
  ];

  const handleMouseEnter = (index) => setHoveredLinkIndex(index);
  const handleMouseLeave = () => setHoveredLinkIndex(null);

  return (
    <div className={styles.menuContainer}>
      <motion.div className={styles.menuIcon} onClick={toggleMenu}>
        <motion.div
          className={styles.line}
          animate={{ rotate: isOpen ? 45 : 0, y: isOpen ? 4 : 0 }}
          transition={{ duration: 0.2 }}
        ></motion.div>
        <motion.div
          className={styles.line}
          animate={{ rotate: isOpen ? -45 : 0, y: isOpen ? -4 : 0 }}
          transition={{ duration: 0.2 }}
        ></motion.div>
      </motion.div>

      <motion.div
        className={styles.navMenu}
        initial={{ opacity: 0, clipPath: "circle(0% at 50% 50%)" }}
        animate={{
          opacity: isOpen ? 1 : 0,
          clipPath: isOpen ? "circle(150% at 50% 50%)" : "circle(0% at 50% 50%)"
        }}
        transition={{ duration: 0.5 }}
      >
        <nav>
          <ul className={styles.navLinks}>
            {navLinks.map((link, index) => (
              <li key={index} className={styles.navItem}>
                <NavLink
                  to={link.path}
                  activeClassName={styles.active}
                  className={`${styles.navLink} ${
                    hoveredLinkIndex !== null && hoveredLinkIndex !== index
                      ? styles.otherLinks
                      : ""
                  }`}
                  onMouseEnter={() => handleMouseEnter(index)}
                  onMouseLeave={handleMouseLeave}
                  onClick={toggleMenu}
                >
                  {link.label}
                </NavLink>
              </li>
            ))}
          </ul>
        </nav>
      </motion.div>
    </div>
  );
};

export default MainMenu;
