import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInstagram } from "@fortawesome/free-brands-svg-icons";
import LanguageToggle from "../LanguageToggle/LanguageToggle";
import styles from "./Footer.module.css";

const Footer = () => {
  const { t } = useTranslation("footer");
  const currentYear = new Date().getFullYear();

  return (
    <footer className={styles.footer}>
      <div className={styles.footerContainer}>
        <a
          href="https://www.instagram.com/jordanolguin_"
          target="_blank"
          rel="noopener noreferrer"
          className={styles.instagramLink}
        >
          <FontAwesomeIcon icon={faInstagram} className={styles.instagramIcon} />
        </a>
        <p className={styles.copyright}>
          &copy; {currentYear} JORDAN OLGUIN. {t("rightsReserved")}
        </p>
        <div className={styles.languageToggle}>
          <LanguageToggle />
        </div>
      </div>
    </footer>
  );
};

export default Footer;
